import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionGetStarted from 'components/SectionGetStarted'
import SDKComponent from 'components/highlighter/Sdk'
const DocsPage = ({ location }) => (
  <Layout
    title="Pentafile docs, una guia para integrar a tus aplicaciones"
    description="Configura en simples pasos, REST API, SDK Y UI"
    location={location}
  >
    <section className="section p-t-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 text-center">
            <h1 className="serif">Pentafile Docs</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="section section__os--feature-bug">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="card card--blue">
              <h3>Comenzar</h3>
              <p>
                Una guía completa para que comiences a utilizar PentaFile en tus
                aplicaciones de negocio.
              </p>
              <Link
                data-track
                data-track-location="open-source"
                track-event="Clicked Go to Forum"
                to="https://pentafile.gitbook.io/docs"
                className="btn btn-secondary"
                target="_blank"
              >
                Ver →
              </Link>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card card--red">
              <h3>Clientes SDK</h3>
              <p>
                Descarga y configura en tus aplicaciones, tenemos clientes para
                diversos lenguajes de programación.
              </p>

              <Link
                data-track
                data-track-location="open-source"
                track-event="Clicked go to Forum"
                to="https://pentafile.gitbook.io/docs/clientes-sdk"
                className="btn btn-secondary"
                target="_blank"
              >
                Ver →
              </Link>
            </div>
          </div>
        </div>
        <section className="section p-t-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2 text-center">
                <h1 className="serif">Integra simple y fácil</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-t-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <SDKComponent />
              </div>
            </div>
          </div>
        </section>
        <section className="section p-t-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
              <Link
                data-track
                data-track-location="open-source"
                track-event="Clicked go to Forum"
                to="https://static.pentafilestore.com/pentafile.html"
                className="btn btn-secondary"
                target="_blank"
              >
                Ver en funcionamiento →
              </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-t-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2 text-center">
                <h1 className="serif">Corta tus imágenes dinamicamente</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-sm-12 center-block">
            <p>
              https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e?resize=width:32,height:32
            </p>
            <img src="https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e?resize=width:32,height:32" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 center-block">
            <p>
              https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e?resize=width:64,height:64
            </p>
            <img src="https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e?resize=width:64,height:64" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e
              <span className="font-weight-bold">
                ?resize=width:200,height:200
              </span>
            </p>
            <img src="https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e?resize=width:200,height:200" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e
            </p>
            <img src="https://cdn.pentafilestore.com/143466cb8ad9f574f290b4ae0df7315e" />
          </div>
        </div>
      </div>
    </section>
    <SectionGetStarted />
  </Layout>
)
export default DocsPage
